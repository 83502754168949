import { datadogRum } from '@datadog/browser-rum';

const DD_APPLICATION_ID = '5d93a81c-b3ce-40b8-96c1-308100251855';
const DD_CLIENT_TOKEN = 'pub16d8a0d72513ce1c263ee6e988a4b187';
const DD_SITE_NAME = 'datadoghq.com';
const DD_SERVICE_NAME = 'fbg-wallet-webview';

const REDACTED_PARAMS = ['billingAddress', 'profile'];

function redactPII(event) {
  REDACTED_PARAMS.forEach((paramKey) => {
    const regex = new RegExp(`${paramKey}=[^&]*`, 'g');
    event.view.url = event.view.url.replaceAll(regex, `${paramKey}=REDACTED`);
  });
}

export function initDatadog() {
  if (process.env.FBG_ENV != null) {
    datadogRum.init({
      applicationId: DD_APPLICATION_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: DD_SITE_NAME,
      service: DD_SERVICE_NAME,
      env: process.env.FBG_ENV || 'fbg-local',
      version: process.env.FBG_TAG || 'v0.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: redactPII,
    });

    datadogRum.startSessionReplayRecording();
  }
}
